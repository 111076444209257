.sidebar-nav .nav-title {
  font-size: 100%;
  font-weight: 900;
}

// .menu1 > a {
//   padding-left: 1px !important;
// }
// .menu2 > a {
//   padding-left: 3.2rem !important;
// }
// .menu3 > a {
//   padding-left: 2.8rem !important;
// }
// .menu4 > a {
//   padding-left: 3.2rem !important;
// }

div.sidebar ul {
  padding-bottom: 0px;
}
