// Here you can add other styles

.blue {
  background-color: blue;
  transition: all 1s ease-in;
}

.bg-less {
  background-color: orange;
}

.small {
  max-width: 500px !important;
}
.min-height-form {
  min-height: 300px;
}
.body {
  height: 100%;
  overflow: hidden;
  background: url("../assets/images/gamec.png");
  overflow: auto;
}

// .actionElementsHeaderStyle > a {
//   color: rgba(44, 56, 74, 0.95) !important;
// }

// .actionElementsTabViewStyle .p-tabview-ink-bar {
//   background-color: rgba(44, 56, 74, 0.95) !important;
// }
.min-height-form {
  min-height: 300px;
}

.p-card-content {
  display: flex;
  justify-content: center;
}

.p-card .p-card-body {
  padding: 0 !important;
}

.p-card .p-card-title {
  background-color: #3c4b64;
  color: white;
}

.min-width-small {
  min-width: 200px;
}

.cursor {
  cursor: pointer;
}

.p-button.p-button-icon-only {
  height: 3rem;
}

.font-size-smaller {
  font-size: 0.8rem;
}

.dropdown-menu {
  inset: 10px 0 auto auto !important;
}

.position-blur {
  z-index: 3000 !important;
}

.f-large {
  font-size: x-large;
}

.p-dialog-title {
  text-align: center;
}

.p-toggleable-content {
  height: 100%;
}

.min-vh-max {
  min-height: calc(100vh - 40px);
}

.primary-color-spinner > .p-progress-spinner-svg > .p-progress-spinner-circle {
  stroke: var(--primary-color);
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
}

.spinner-white > .p-progress-spinner-svg > .p-progress-spinner-circle {
  stroke: white;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
}
