//title
.dark-mode legend.p-fieldset-legend {
  background: $dark-gray-300;
  border: 1px solid black;
}

//body
.dark-mode fieldset.p-fieldset {
  background: $dark-gray-500;
  border: 1px solid black;
}
