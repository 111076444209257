.avatar-img {
  border-radius: inherit !important;
}

.avatar-xs {
  width: 1rem !important;
  height: 1rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary-color-darken);
}
