.card {
  color: black;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.bg-light {
  background-color: rgb(6, 4, 4) !important;
}

.logo {
  width: 100px;
  height: auto;
}

.main {
  background-image: url("../../assets/images/gamec.webp");
  background-size: cover;
  /* -webkit-animation: slidein 50s;
  animation: slidein 50s;
  top: 0;
  right: 0;
  bottom: 0;
  left: -1000px;

  overflow-x: hidden;

  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;

  -webkit-animation-direction: alternate;
  animation-direction: alternate;*/
  background-size: 2050px;
  height: 100%;
}

/* @-webkit-keyframes slidein {
  from {
    background-size: 3000px;
    transform: translateX(0);
  }
  to {
    background-size: 2750px;
    transform: translateX(500px);
  }
}

@keyframes slidein {
  from {
    background-size: 3000px;
    transform: translateX(0);
  }
  to {
    background-size: 2750px;
    transform: translateX(500px);
  }
} */

.context {
  /* display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; */
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(38, 56, 132, 0.3);
  border-radius: 3px;
}
