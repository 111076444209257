.p-datatable .p-datatable-tbody > tr {
  &.table-warning {
    background-color: rgba($color: #f4f4f4, $alpha: 1);
    opacity: 0.8;
  }
}

.role-admin {
  color: var(--yellow-500);
  font-weight: 700;
}

.role-user {
  color: rgb(160, 172, 226);
}

.role-all {
  color: var(--blue-500);
}

.p-datatable-emptymessage {
  font-size: xx-large;
}

.p-datatable-emptymessage > td {
  margin-bottom: 50px;
}

.p-datatable-wrapper {
  //height: 100%;
  background-color: white;
}

.p-datatable > .p-component-overlay {
  background-color: rgb(255 255 255 / 57%) !important;
}

.p-datatable-flex-scrollable {
  max-height: 100%;
}

table {
  caption-side: bottom;
  border-collapse: separate;
}

.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  > tr:not(:first-of-type)
  > th {
  border-top: 0;
}

.bookmaker-max {
  color: orange !important;
  font-weight: 700;
}

.bookmaker-min {
  color: rgb(25, 65, 197) !important;
  font-weight: 700;
}

.playerPerfHeaderColumn,
.playerPerfBet,
.playerPerfUni,
.playerPerfWin,
.playerPerfPS {
  padding: 5px !important;
}

.playerPerfDate,
.playerPerfMatch,
.playerPerfPlayer,
.playerPerfJoueur {
  padding: 3px !important;
}

.p-datatable .p-column-header-content {
  display: block !important;
}

.p-datatable .p-datatable-footer {
  margin-top: -1rem !important;
  background: rgb(255, 255, 255) !important;
}

.playerPerfHeaderColumn {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

// .p-datatable-tbody > tr:hover:not(.p-highlight) > .playerPerfBet,
// .p-datatable-tbody > tr:hover:not(.p-highlight) > .playerPerfWin {
//   background-color: #e9ecef;
// }

.p-highlight > .playerPerfBet,
.p-highlight > .playerPerfWin {
  background-color: #eef2ff;
}

.p-datatable-tbody > tr:not(.p-highlight) > .playerPerfBet {
  background-color: #f8f9fa;
}

.p-datatable-tbody > tr:not(.p-highlight) > .playerPerfWin {
  background-color: #f8f9fa;
}
