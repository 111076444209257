// .bg-light.dark {
//   background-color: #{$dark-sidebar-bg} !important;
// }

//couleur des p-button-success
.dark-mode button.p-button-success {
  color: $dark-green-600 !important;
}

//couleur de fond des inputtext
.dark-mode input.p-inputtext {
  background: $dark-gray-500 !important;
  border: 1px solid black;
}

.dark-mode .p-inputswitch-checked .p-inputswitch-slider {
  background: $dark-blue-800 !important;
}

.dark-mode .p-dropdown {
  background-color: #9da5b1;
}

.dark-mode .p-dropdown-panel {
  background: #9da5b1;
}
