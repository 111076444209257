.dark-mode .p-datatable-header {
  background: $dark-gray-700 !important;
  border-width: 0px !important;
}

//header contenant la pagination
.dark-mode .p-datatable-header div:only-child {
  background: $dark-gray-700;
  border-color: black;
  //border-width: 0 0 0 0 !important;
}

//intérieur du champ input
.dark-mode .p-datatable-header input.p-inputtext {
  background: $dark-gray-500 !important;
  border: 1px solid black;
}

//titres de la datatable
.dark-mode thead th {
  background: $dark-gray-300 !important;
  border: 1px solid black !important;
  border-width: 0 0 1px 0 !important;
}
//checkbox de la datatable
.dark-mode thead .p-checkbox-box {
  background: $dark-gray-300 !important;
  border: 1px solid black;
}

//body
.dark-mode tbody td {
  background: $dark-gray-500 !important;
  border: 1px solid black !important;
  border-width: 0 0 1px 0 !important;
}
//checkbox du body
.dark-mode tbody .p-checkbox-box {
  background: $dark-gray-500 !important;
  border: 1px solid black;
}
.dark-mode tbody .p-checkbox-box:hover {
  border-color: $dark-blue-500 !important;
}

.dark-mode .p-datatable-wrapper {
  background: $dark-gray-500 !important;
  //border: 1px solid black !important;
  //border-width: 0 0 1px 0 !important;
}
.dark-mode .p-datatable-footer {
  background: $dark-gray-500 !important;
  border: 1px solid $dark-gray-500 !important;
}
.dark-mode .p-datatable-footer .p-paginator {
  background: $dark-gray-500 !important;
}

.dark-mode .p-datatable-footer .p-dropdown {
  background: $dark-gray-500 !important;
  border: 1px solid black !important;
}

.dark-mode .p-paginator-page.p-highlight {
  background: $dark-blue-100 !important;
}

.dark-mode .p-paginator-pages button.p-highlight {
  background: $dark-blue-900 !important;
}

.dark-mode .p-paginator {
  background-color: black;
}
