body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

.pl-rem > a {
  padding-left: 5rem !important;
}

.container {
  /* height: 100%; */
  max-width: 100%;
  margin-left: 0px;
  margin-right: 0px;
  /* overflow: scroll; */
}

.wrapper {
  height: 100%;
}

@font-face {
  font-family: "HighSchoolUSA";
  src: url("./assets/fonts/High\ School\ USA\ Sans.ttf") format("truetype");
  /* Autres formats de police pour la compatibilité avec différents navigateurs */
}
